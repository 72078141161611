



.how__tabs-nav {
    a {
        .how__tabs-nav-number {
            display: none;
            @media screen and (max-width: $breakpoint-l) {
                display: block;
            }
        }
    }
}
