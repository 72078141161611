/* ********** Buttons ********** */
.btn { 
    display : inline-block;
    position: relative;
    font-weight: 600;
    text-decoration: none;
    cursor: pointer;
    text-align: center;
}

.btn-primary {
    color: #fff;
    text-shadow: 0 2px 4px rgba(80, 39, 166, .5);
    z-index: 1;
    background: blue;
    top: 4px;
    background: -webkit-linear-gradient(right, #5027A6, #4EA0FF);
    background: -moz-linear-gradient(right, #5027A6, #4EA0FF);
    background: linear-gradient(to left, #5027A6, #4EA0FF);
    box-shadow: 
        0px 22px 20px -20px rgba(16,88,221,0.3), 
        0px 3px 12px 0px rgba(77,160,255,0.2);
    transition: line-height .3s ease;
}
    .btn-primary span { position: relative; top: 0; transition: top .3s ease;}
    .btn-primary:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: -4px;
        left: 0;
        z-index: -1;
        background: -webkit-linear-gradient(right, #86CEFF, #7B4FD4);
        background: -moz-linear-gradient(right, #86CEFF, #7B4FD4);
        background: linear-gradient(to left, #86CEFF, #7B4FD4);
        transition: margin .3s ease;
    }
.btn-primary:hover {
    box-shadow: 
        0px 22px 20px -20px rgba(122,79,212,0.5), 
        0px 3px 12px 0px rgba(169,128,254,0.4);
}
    .btn-primary:hover span { top: -3px;}
    .btn-primary:hover:before { 
        background: -webkit-linear-gradient(right, #4EA0FF, #7B4FD4);
        background: -moz-linear-gradient(right, #4EA0FF, #7B4FD4);
        background: linear-gradient(to left, #4EA0FF, #7B4FD4);
        margin-top: -3px;
    }

.btn-secondary {
    color: #5027A6;
    background: -webkit-linear-gradient(right, rgba(134, 206, 255, .5), rgba(123, 79, 212, .5));
    background: -moz-linear-gradient(right, rgba(134, 206, 255, .5), rgba(123, 79, 212, .5));
    background: linear-gradient(to left, rgba(134, 206, 255, .5), rgba(123, 79, 212, .5));
    z-index: 1;

    &.disabled {
        opacity: 0.5;
        cursor: default;
    }
}
    .btn-secondary:before {  
        content: "";
        position: absolute;
        top: 3px; left: 3px; bottom: 3px; right: 3px;
        background: #fff;
        z-index: -1;
    }

.btn-secondary:hover {
    background: -webkit-linear-gradient(right, rgba(134, 206, 255, 1), rgba(123, 79, 212, 1));
    background: -moz-linear-gradient(right, rgba(134, 206, 255, 1), rgba(123, 79, 212, 1));
    background: linear-gradient(to left, rgba(134, 206, 255, 1), rgba(123, 79, 212, 1));
}
    .btn-secondary:hover:before {}

.btn-noBgd { 
    font-size: 30px;
    text-decoration: none;
    color: #1058DD; 
    font-weight: 500;
    position: relative;
    display: inline-block;
    transition: top .3s ease;
    top: 0;
}
    .btn-noBgd:before { 
        content: "";
        position: absolute;
        width: 100%;
        display: block;
        left: 0;
        bottom: 5px;
        border-bottom: 2px rgba(77, 160, 255, .4) dashed;   
    }
.btn-noBgd:hover { color: #7A4FD4; top: -3px;}
    .btn-noBgd:hover:before { border-bottom: 2px rgba(169, 128, 254, .4) dashed; }


.btn-XS {}
.btn-S {}
.btn-M, .btn {
    border-radius: 15px;
    height: 60px;
    line-height: 60px;
    padding: 0 30px;
    font-size: 22px;
    letter-spacing: 1px;
}
    .btn-M, .btn:before { border-radius: 15px;}
    .btn-M.btn-secondary:before, .btn.btn-secondary:before { border-radius: 12px;}
.btn-L {
    border-radius: 30px;
    height: 75px;
    line-height: 75px;
    padding: 0 50px;
    font-size: 28px;
    letter-spacing: 1px;
}
    .btn-L:before { border-radius: 30px;}
    .btn-L.btn-secondary:before { border-radius: 27px;}
.btn-XL {}


@media screen and (max-width: 1279px) { 
    .btn-primary:before { top: -3px;}
    .btn-secondary:before { top: 2px; left: 2px; bottom: 2px; right: 2px;}
    
    .btn-M, .btn { border-radius: 12px; height: 50px; line-height: 50px; padding: 0 20px; font-size: 18px;}
    .btn-M, .btn:before { border-radius: 12px;}
    .btn-M.btn-secondary:before, .btn.btn-secondary:before { border-radius: 10px;}
    
    .btn-L { border-radius: 25px; height: 65px; line-height: 65px; padding: 0 30px; font-size: 24px;}
    .btn-L:before { border-radius: 25px;}
    .btn-L.btn-secondary:before { border-radius: 23px;}
    
    .btn-noBgd { font-size: 28px;}
    .btn-noBgd:before { bottom: 6px;}
}

@media screen and (max-width: 1023px) { 
    .btn-noBgd { font-size: 24px;}
    .btn-noBgd:before { bottom: 2px;}
}

@media screen and (max-width: 767px) { 
    .btn-primary { 
        top: 0px;
        &:before {top: 0px;}
    }
    
    .btn-M, .btn { border-radius: 10px; height: 40px; line-height: 40px; padding: 0 15px; font-size: 15px; letter-spacing: .2px;}
    .btn-M:before, .btn:before { border-radius: 10px;}
    .btn-M.btn-secondary:before, .btn.btn-secondary:before { border-radius: 8px;}
    
    .btn-L { border-radius: 16px; height: 44px; line-height: 44px; padding: 0 20px; font-size: 16px; letter-spacing: 0;}
    .btn-L:before { border-radius: 16px;}
    .btn-L.btn-secondary:before { border-radius: 14px;}
    
    .btn-noBgd { font-size: 18px;}
}

@media screen and (max-width: 374px) { 
    .btn-L { padding: 0 15px; font-size: 15px;}
}
