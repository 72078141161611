
$rounded-image-large-width : 150px;
$rounded-image-medium-width : 120px;


@mixin rounded-image($width) {
    margin: 0 auto;
    overflow: hidden;
    position: absolute;
    left: 50%;
    border-radius: 100%;
    margin-left: calc(-#{$width}/2);
    border: 5px solid white;
    height: $width;
    width: $width;
    top: calc(-#{$width}/3);
    background: #cdeeff;
    img {
        height: 100%;
    }
}


.why__list {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    margin-top: 20px;
    .why__point {
        flex: 0 0 370px;
        position: relative;
        background: #fff;
        border-radius: 30px;
        padding: 130px 40px 40px 40px;
        box-shadow: 0px 20px 50px -50px rgba(16, 88, 221, 0.5),
                    0px 4px 16px 0px rgba(9, 31, 101, 0.15);
        list-style: none;
    
    
        @media screen and (max-width: $breakpoint-xl) {
            flex: 0 0 288px;
            padding: 105px 30px 30px 30px;
        }
    
        @media screen and (max-width: $breakpoint-l) {
            flex: 1;
            padding: 90px 30px 20px;
            margin-bottom: 64px;
        }
    
        .rounded-image {
    
            @include rounded-image($rounded-image-large-width);
    
            @media screen and (max-width: $breakpoint-m) {
                @include rounded-image($rounded-image-medium-width);
            }
    
        }
    }
}
