/* ------------------------------------------------------------ */
/* CHOOSE PLAN */
/* ------------------------------------------------------------ */
.plans {
    padding: 80px 0;
    text-align: center;
    box-shadow: 0 4px 16px rgba(9, 31, 101, 0.15);
}

.plans__header {
    position: relative;
    margin: 0 0 50px 0;
    padding-top: 30px;
}

.plans__header:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -40px;
    width: 80px;
    height: 6px;
    border-radius: 3px;
    background: linear-gradient(90deg,
            rgba(169, 128, 254, 1) 0%,
            rgba(212, 183, 255, 1) 100%);
}

.plans__header h5 {
    font-size: 28px;
    font-weight: 400;
    color: #718ea3;
    letter-spacing: 1px;
}

.plans__header h3 {
    letter-spacing: 1px;
}

.plans__header h3 strong {
    color: #082fae;
}

.plans__list {
    display: flex;
    justify-content: space-between;
}

.plan {
    cursor: pointer;
    flex: 0 0 370px;
    position: relative;
    border-radius: 50px;
    box-shadow: 0 4px 18px rgba(77, 160, 255, 0.3);
    transition: 0.3s ease;
}

.plan__wrapper {
    margin: 1px 0 4px 0;
    border-radius: 50px;
    padding: 10px;
    background: #fff;
    height: 99%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.plan__radio-btn {
    position: relative;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin: 20px auto;
}

.plan__radio-btn:after {
    content: "";
    display: block;
    position: absolute;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    left: 50%;
    top: 50%;
    margin-left: -15px;
    margin-top: -15px;
    opacity: 0;
}

.plan__title {
    padding: 0 50px;
    font-size: 34px;
    line-height: 1.2;
}

#plan-family .plan__title {
    padding: 0 40px;
}

.plan__offer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 30px;
    line-height: 1.4;
}

.plan__offer li {
    list-style: none;
    position: relative;
    padding: 0 0 0 38px;
    margin: 5px 0;
    text-align: left;
    font-size: 18px;
}

.plan__offer li:after {
    content: "";
    position: absolute;
    display: block;
    height: 15px;
    width: 18px;
    left: 0;
    top: 50%;
    margin-top: -9px;
    background-repeat: no-repeat;
    background-size: contain;
}

.plan__price {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 140px;
    border-radius: 40px;
    padding: 0 30px;
    font-size: 44px;
    line-height: 1.3;
    font-weight: 500;
    letter-spacing: 1.5px;
}

.plan__price-info {
    display: block;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0;
}

/* Hover and Selected States */
.plan:hover {
    margin-top: -6px;
    box-shadow: 0 6px 30px rgba(77, 160, 255, 0.4);
}

.plan:hover .plan__radio-btn:after {
    opacity: 0.1;
}

.plan.is-active,
.plan.is-active:hover {
    cursor: default;
    margin-top: -6px;
    box-shadow: 0 3px 12px rgba(77, 160, 255, 0.3);
}

.plan.is-active:before {
    content: "";
    display: block;
    position: absolute;
    left: -15px;
    right: -15px;
    top: -15px;
    bottom: -15px;
    background: rgba(133, 206, 255, 0.1);
    box-shadow: 0 3px 12px rgba(8, 47, 174, 0.1) inset;
    border-radius: 65px;
    z-index: -1;
}

.plan.is-active .plan__radio-btn:after,
.plan.is-active:hover .plan__radio-btn:after {
    opacity: 1;
}

/* Purple plan */
.plan-purple {
    background: linear-gradient(90deg,
            rgba(169, 128, 254, 1) 0%,
            rgba(212, 183, 255, 1) 100%);
}

.plan-purple .plan__radio-btn {
    background: rgba(122, 79, 212, 0.08);
    box-shadow: 0 1px 4px rgba(8, 47, 174, 0.1) inset;
}

.plan-purple .plan__radio-btn:after {
    background: radial-gradient(ellipse at center, #d5b7ff, #aa80fe);
}

.plan-purple .plan__offer li:after {
    background-image: url('~@assets/img/plan__check-purple@2x.png');
}

.plan-purple .plan__price {
    background: rgba(122, 79, 212, 0.1);
    color: #5027a6;
}

/* Blue plan */
.plan-blue {
    background: linear-gradient(90deg,
            rgba(78, 160, 255, 1) 0%,
            rgba(134, 206, 255, 1) 100%);
}

.plan-blue .plan__radio-btn {
    background: rgba(133, 206, 255, 0.2);
    box-shadow: 0 1px 4px rgba(8, 47, 174, 0.1) inset;
}

.plan-blue .plan__radio-btn:after {
    background: radial-gradient(ellipse at center, #87ceff, #4ea0ff);
}

.plan-blue .plan__offer li:after {
    background-image: url('~@assets/img/plan__check-blue@2x.png');
}

.plan-blue .plan__price {
    background: rgba(133, 206, 255, 0.2);
    color: #1058dd;
}

.plan-blue .plan__price-info {
    color: #091f65;
}

/* Orange plan */
.plan-orange {
    background: linear-gradient(90deg,
            rgba(238, 125, 21, 1) 0%,
            rgba(248, 180, 47, 1) 100%);
}

.plan-orange .plan__radio-btn {
    background: rgba(250, 183, 87, 0.12);
    box-shadow: 0 1px 4px rgba(142, 65, 13, 0.15) inset;
}

.plan-orange .plan__radio-btn:after {
    background: radial-gradient(ellipse at center, #f8b530, #ee7d16);
}

.plan-orange .plan__offer li:after {
    background-image: url('~@assets/img/plan__check-orange@2x.png');
}

.plan-orange .plan__price {
    background: rgba(250, 183, 87, 0.15);
    color: #ef7d15;
}

.plan-orange .plan__price-info {
    color: #8e410d;
}

/* Signup form */
.suscribe-form {
    display: flex;
    flex-wrap: wrap;
    width: 830px;
    margin: 70px auto 0 auto;
    padding: 20px;
    background: rgba(133, 206, 255, .1);
    box-shadow: 0 3px 12px rgba(8, 47, 174, .1) inset;
    border-radius: 30px;
}

.suscribe-from__inputs {
    flex: 0 0 100%;
    display: flex;
    margin: 0 0 20px 0;
}

.suscribe-from__inputs li {
    position: relative;
    list-style: none;
}

.suscribe-form__prenom-wrapper {
    flex: 1 1 210px;
}

.suscribe-form__nom-wrapper {
    flex: 1 1 210px;
    margin: 0 10px;
}

.suscribe-form__email-wrapper {
    flex: 1 1 350px;
}

.suscribe-form__cgv-wrapper {
    flex: 1;
    display: flex;
    align-items: center;
    height: 60px;
}

#suscribe-form__CGV {
    text-align: left;
}

.suscribe-form__cgv-wrapper label {
    margin-left: 20px;
}

.suscribe-form__cgv-wrapper label a {
    color: #718EA3;
    text-decoration: underline;
}

.suscribe-form__cgv-wrapper label a:hover {
    color: #7A4FD4;
}

#suscribe-form__btn {
    flex: 0 0 220px;
}

@media screen and (max-width: 1279px) {
    .plans__header {
        margin: 0 0 40px 0;
        padding-top: 20px;
    }

    .plan {
        flex: 0 0 278px;
        border-radius: 30px;
    }

    .plan__wrapper {
        padding: 5px;
        border-radius: 30px;
    }

    .plan__radio-btn {
        width: 34px;
        height: 34px;
    }

    .plan__radio-btn:after {
        width: 26px;
        height: 26px;
        border-radius: 13px;
        margin-left: -13px;
        margin-top: -13px;
    }

    .plan__title {
        padding: 0 30px;
        font-size: 24px;
    }

    #plan-family .plan__title {
        padding: 0 40px;
    }

    .plan__offer {
        height: 130px;
        padding: 0 20px;
    }

    .plan__offer li {
        padding: 0 0;
        font-size: 14px;
    }

    .plan__offer li:after {
        display: none;
    }

    .plan__price {
        height: 120px;
        border-radius: 25px;
        padding: 0 15px;
        font-size: 36px;
    }

    .plan__price-info {
        font-size: 14px;
    }

    .plan.is-active:before {
        left: -12px;
        right: -12px;
        top: -12px;
        bottom: -12px;
        border-radius: 42px;
    }
}

@media screen and (max-width: 1023px) {
    .plans {
        padding: 70px 0;
    }

    .plans__header {
        margin: 0 0 30px 0;
    }

    .plans__header h5 {
        font-size: 22px;
    }

    .plans__list {
        flex-direction: column;
    }

    .plan {
        flex: 1;
        margin: 0 0 40px 0;
    }

    .plan__wrapper {
        padding: 20px 0;
        position: relative;
    }

    .plan__radio-btn {
        position: absolute;
        left: 30px;
        top: 35px;
        margin: 0;
    }

    .plan__title,
    #plan-family .plan__title {
        padding: 0 240px 10px 94px;
        font-size: 28px;
        text-align: left;
    }

    .plan__offer {
        height: inherit;
        padding: 0 240px 0 94px;
    }

    .plan__offer li {
        padding: 0 0 0 0;
        font-size: 16px;
    }

    .plan__offer li:after {
        display: block;
        left: -50px;
    }

    .plan__price {
        height: inherit;
        position: absolute;
        width: 200px;
        right: 10px;
        top: 10px;
        bottom: 10px;
        padding: 0 20px;
        border-radius: 20px;
    }

    .plan__price-info {
        font-size: 13px;
    }

    .suscribe-form {
        width: 688px;
        margin: 20px auto 0 auto;
    }
}

@media screen and (max-width: 767px) {
    .plans {
        padding: 50px 0;
    }

    .plans__header {
        margin: 0 0 20px 0;
        padding-top: 15px;
        text-align: left;
    }

    .plans__header:before {
        left: 0;
        margin-left: 0;
        width: 60px;
        height: 4px;
        border-radius: 2px;
    }

    .plans__header h5 {
        font-size: 16px;
        letter-spacing: 0.1px;
        padding: 0 0 5px 0;
    }

    .plan {
        margin: 0 0 30px 0;
        border-radius: 20px;
        opacity: 0.7;
    }

    .plan.is-active {
        opacity: 1;
    }

    .plan__wrapper {
        border-radius: 20px;
        padding: 10px;
    }

    .plan__radio-btn {
        width: 26px;
        height: 26px;
        left: 20px;
        top: 25px;
    }

    .plan__radio-btn:after {
        width: 20px;
        height: 20px;
        border-radius: 10px;
        margin-left: -10px;
        margin-top: -10px;
    }

    .plan__title,
    #plan-family .plan__title {
        padding: 5px 10px 5px 56px;
        font-size: 22px;
    }

    .plan__offer {
        padding: 0 10px 10px 56px;
    }

    .plan__offer li {
        font-size: 14px;
    }

    .plan__offer li:after {
        height: 13px;
        width: 16px;
        margin-top: -8px;
        left: -40px;
    }

    .plan__price {
        height: inherit;
        position: relative;
        width: 100%;
        right: 0;
        top: 0;
        bottom: 0;
        border-radius: 15px;
        font-size: 32px;
        padding: 10px 20px;
    }

    .plan.is-active:before {
        left: -10px;
        right: -10px;
        top: -10px;
        bottom: -10px;
        border-radius: 30px;
    }

    .suscribe-form {
        width: 100%;
        margin: 20px auto 0 auto;
        padding: 10px;
        border-radius: 20px;
        justify-content: right;
    }

    .suscribe-from__inputs {
        margin: 0 0 10px 0;
        flex-wrap: wrap;
    }

    .suscribe-form__prenom-wrapper {
        flex: 0 0 48%;
    }

    .suscribe-form__nom-wrapper {
        flex: 0 0 48%;
        margin: 0 0 7px 2%;
    }

    .suscribe-form__cgv-wrapper {
        height: 30px;
        flex: 0 0 100%;
        margin: 0 0 10px 0;
    }

    .suscribe-form__cgv-wrapper label {
        margin-left: 12px;
    }

    #suscribe-form__btn {
        flex: 0 0 150px;
        margin: 0 0 2px 0;
    }

    .loader-content {
        transition: 0.1s;
    }

    .main-content {
        transition: 0.1s;
    }

}
.error-message {
    color: $color-error;
}

.plans {
    .plans__header {
        h5 {
            text-align: center;
            @media screen and (max-width: $breakpoint-sm) {
                text-align: left;
            }
        }
        .subtitle {
            display: flex;
            margin-bottom: 29px;
            align-items: center;
            h3 {
                text-align: right;
                @media screen and (max-width: $breakpoint-sm) {
                    text-align: left;
                }
            }
            .starry-wip {
                padding-left: 40px;
                // @media screen and (max-width: $breakpoint-m) {
                //     padding-left: 30px;
                // }
                @media screen and (max-width: $breakpoint-sm) {
                    padding-left: 10px;
                }
                img {
                    height: 120px;
                    @media screen and (max-width: $breakpoint-xl) {
                        height: 100px;
                    }
                    @media screen and (max-width: $breakpoint-m) {
                        height: 80px;
                    }
                }
            }
        }
    }
}