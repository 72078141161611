


































































.legals {
  h2 {
    color: #3b3f4a;
    font-size: 50px;
    font-weight: 500;
    line-height: 1.2;
    font-family: "Baloo 2", sans-serif;
  }
  h3 {
    font-size: 40px;
    font-weight: 500;
    line-height: 1.3;
    font-family: "Baloo 2", sans-serif;
  }
  .page-main {
    padding: 36px;
  }
  .page-main h1 {
    text-align: center;
  }
}
