
/* ------------------------------------------------------------ */
/* FOOTER */
/* ------------------------------------------------------------ */
.stowy-footer { background: #3B3F4A; padding: 40px 50px; font-size: 18px; color: #A9C4D7; font-weight: 500; }
    .stowy-footer .section-wrapper { display: flex; align-items: center;}

    .footer__left { flex: 0 0 300px;}
        .footer__left li { list-style: none;}
            .footer__left a { color: #A9C4D7; text-decoration: dashed; text-decoration-color: #4A6272;  }
        
    .footer__center { flex: 1; text-align: center; }
        .footer__logo { 
            display: block;
            margin: 0 auto 10px auto;
            width: 125px;
            height: 80px;
        }
        .footer__social {}
            .footer__social-btn {  
                display: inline-block;
                width: 36px;
                height: 39px;
                margin: 0 6px;
                background-size: 36px 78px;
                background-position: center top;
            }
            .footer__social-btn:hover { background-position: center bottom;}
            .footer__facebook { background-image: url('~@assets/img/footer__Facebook@2x.png');}
            .footer__twitter { background-image: url('~@assets/img/footer__Twitter@2x.png');}
            .footer__insta { background-image: url('~@assets/img/footer__Insta@2x.png');}
        
    .footer__copyright { flex: 0 0 300px; text-align: right}



@media screen and (max-width: 1279px) {}

@media screen and (max-width: 1023px) {
    .stowy-footer { padding: 30px; font-size: 16px; }
    .footer__left { flex: 0 0 240px;}
    .footer__copyright { flex: 0 0 240px;}

}

@media screen and (max-width: 767px) {
    .stowy-footer { padding: 30px 20px; font-size: 14px; }
    .stowy-footer .section-wrapper { flex-direction: column;}
    .footer__left { flex: 1; text-align: center; order: 2; margin: 20px 0;}
    .footer__center { display: flex; justify-content: space-between; width: 100%;}
    .footer__copyright { flex: 1; text-align: center; order: 3;} 
    .footer__logo { margin: 0; width: 90px; height: 59px;}
    .footer__social-btn { background-position: center bottom; margin: 0 0 0 5px;}
}
