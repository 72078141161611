

/* ------------------------------------------------------------ */
/* HEADER */
/* ------------------------------------------------------------ */
.main-head {
    position: relative;
    text-align: center;
    padding: 40px 60px 0 60px;
}

.main-head {
    background-image: url('~@assets/img/Head-clouds-bgd@2x.png'), url('~@assets/img/Head-Moon@2x.png'), url('~@assets/img/Head-Stars@2x.png'), url('~@assets/img/Head-bgd.jpg');
    background-repeat: repeat-x, no-repeat, no-repeat, no-repeat;
    background-size: 1000px 450px, 1200px 600px, 2000px 400px, cover;
    background-position: center bottom, center top -120px, center top -80px, center;
}

.section-wrapper {
    position: relative;
    z-index: 10;
}

.header__logo {}

.header__logo img {
    display: block;
    width: 150px;
    height: 96px;
    margin: 0 auto 10px auto;
}

.main-head__title {
    font-size: 55px;
    color: #fff;
    text-shadow: -1px 3px 1px rgba(26, 41, 77, 0.5);
    letter-spacing: 1px;
    font-weight: 500;
}

.main-head__title span {
    color: #f7e68e;
}

.main-head__descr {
    color: #fff;
    font-size: 24px;
    line-height: 1.5;
    letter-spacing: 1px;
    text-shadow: -1px 3px 1px rgba(26, 41, 77, 0.5);
    font-weight: 500;
    max-width: 800px;
    margin: 20px auto 0 auto;
}

.main-head__descr span {
    color: #f7e68e;
    font-weight: 600;
}

.main-head__video {
    position: relative;
    width: 820px;
    padding: 10px;
    margin: 30px auto 60px auto;
    border-radius: 30px;
    background: #fff;
    box-shadow: 0px 30px 50px -50px rgba(16, 88, 221, 0.5),
        0px 4px 16px 0px rgba(9, 31, 101, 0.15);
}

.main-head__video iframe {
    border-radius: 20px;
    display: block;
    width: 800px;
    height: 450px;
}

.main-head__callout-btns {
    padding: 0 0 60px 0;
}

.main-head__callout-btns a {
    margin: 0 10px;
}


/* ********** Parallax ********** */
.parallax {
    position: absolute;
    z-index: 1;
}

.parallax-cloud {
    width: 100%;
    bottom: 0;
    left: 0;
    height: 450px !important;
    background-image: url('~@assets/img/Head-clouds-bgd@2x.png');
    background-repeat: repeat-x;
    background-size: 1000px 450px;
    z-index: 4;
}

.parallax-moon {
    width: 1200px;
    height: 500px !important;
    top: 0;
    left: 50%;
    margin-left: -600px;
    z-index: 3;
}

.parallax-stars {
    width: 2000px;
    height: 400px !important;
    top: -40px;
    left: 50%;
    margin-left: -1000px;
    z-index: 2;
}

.parallax-bgd {
    width: 100%;
    height: 100% !important;
    top: 0;
    left: 0;
}

@media screen and (max-width: 1279px) {
    .main-head__descr {
        font-size: 20px;
        max-width: 688px;
    }

    .parallax-moon {
        width: 1000px;
        height: 500px;
        top: -120px;
        margin-left: -530px;
    }

    .parallax-stars {
        width: 1250px;
        height: 250px;
        top: -30px;
        margin-left: -625px;
    }
}

@media screen and (max-width: 1023px) {
    .main-head {
        padding: 30px 0 0 0;
        background-size: 500px 275px, 500px 250px, 1000px 200px, cover;
        background-position: center bottom, right 20px top -70px, center top -15px, center;
    }

    .main-head__title {
        font-size: 40px;
    }

    .main-head__video {
        width: 684px;
    }

    .main-head__video iframe {
        width: 664px;
        height: 372px;
    }

    .main-head__callout-btns {
        padding: 0 0 40px 0;
    }

    .parallax-cloud {
        height: 400px;
        background-size: 910px 400px;
    }

    .parallax-moon {
        width: 750px;
        height: 375px;
        top: -80px;
        margin-left: -400px;
    }
}

@media screen and (max-width: 767px) {
    .main-head {
        padding: 30px 0 0 0;
        background-size: 500px 275px, 500px 250px, 1000px 200px, cover;
        background-position: center bottom, right 20px top -70px, center top -15px, center;
    }

    .header__logo img {
        width: 121px;
        height: 78px;
    }

    .main-head__title {
        font-size: 25px;
        letter-spacing: 0;
    }

    .main-head__title span { display: block;}

    #kidName{
        display: block;
        height: 25px; // equals font-size, todo : replace it by a sass variable
    }

    .main-head__descr {
        font-size: 16px;
        letter-spacing: 0.2px;
    }

    .main-head__title,
    .main-head__descr {
        text-shadow: -1px 1px 1px rgba(26, 41, 77, 0.5);
    }

    .main-head__video {
        width: 335px;
        padding: 5px;
        margin: 20px auto 30px auto;
        border-radius: 20px;
    }

    .main-head__video iframe {
        border-radius: 15px;
        width: 325px;
        height: 180px;
    }

    .main-head__callout-btns {
        padding: 0 0 30px 0;
    }

    .main-head__callout-btns a {
        margin: 0 5px;
    }

    .parallax-cloud {
        height: 275px;
        background-size: 500px 275px;
    }

    .parallax-moon {
        width: 500px;
        height: 250px;
        margin-left: -350px;
    }

    .parallax-stars {
        width: 1000px;
        height: 200px;
        top: -15px;
        margin-left: -500px;
    }
}

@media screen and (max-width: 374px) {
    .main-head__video {
        width: 285px;
        padding: 5px;
        margin: 20px auto 30px auto;
        border-radius: 20px;
    }

    .main-head__video iframe {
        border-radius: 15px;
        width: 275px;
        height: 152px;
    }
}

.parallax-bgd {
    .Masthead__image {
        height: 100% !important;
    }
}

@media screen and (max-width: 1279px) {
    .main-head {
        background-size: 1000px 450px, 900px 450px, 1250px 250px, cover;
        background-position: center bottom, center top -120px, center top -30px, center;
    }

    .parallax-moon { width: 900px; height: 450px; top: -120px; margin-left: -530px;}

    .parallax-stars {
        width: 1250px;
        height: 250px;
        top: -30px;
        margin-left: -625px;
    }
}


@media screen and (max-width: 374px) {
    #kidName{
        display: block;
    }
}






//// FOURRE TOUT DE SECOURS

@media screen and (max-width: 1279px) {   
    .main-head {
        background-size: 1000px 450px, 900px 450px, 1250px 250px, cover;
        background-position: center bottom, center top -120px, center top -30px, center;
    }
    
    .main-head__descr { font-size: 20px; max-width: 688px;}
    
    .parallax-moon { width: 900px; height: 450px; top: -120px; margin-left: -530px;}
    .parallax-stars { width: 1250px; height: 250px; top: -30px; margin-left: -625px;}
}

@media screen and (max-width: 1023px) {
    .main-head {
        padding: 40px 40px 0 40px;
        background-size: 910px 400px, 700px 350px, 1250px 250px, cover;
        background-position: center bottom, center top -80px, center top -30px, center;
    }
    .main-head__title { font-size: 40px;}
    .main-head__video { width: 684px;}
    .main-head__video iframe { width: 664px; height: 372px;}
    .main-head__callout-btns { padding: 0 0 40px 0;}
    
    .parallax-cloud { height: 400px; background-size: 910px 400px;}
    .parallax-moon { width: 750px; height: 375px; top: -80px; margin-left: -400px;}
}

@media screen and (max-width: 767px) {
    .main-head {
        padding: 30px 0 0 0;
        background-size: 500px 275px, 500px 250px, 1000px 200px, cover;
        background-position: center bottom, right 20px top -70px, center top -15px, center;
    }
    .header__logo img { width: 121px; height: 78px;}
    .main-head__title { font-size: 25px; letter-spacing: 0;}
    .main-head__title span { display: block;}
    .main-head__descr { font-size: 16px; letter-spacing: .2px;}
    .main-head__title,
    .main-head__descr { text-shadow: -1px 1px 1px rgba(26, 41, 77, .5);}
    .main-head__video { width: 335px; padding: 5px; margin: 20px auto 30px auto; border-radius: 20px;}
    .main-head__video iframe { border-radius: 15px; width: 325px; height: 180px;}
    .main-head__callout-btns { padding: 0 0 30px 0;}
    .main-head__callout-btns a { margin: 0 5px;}
    
    .parallax-cloud { height: 275px; background-size: 500px 275px;}
    .parallax-moon { width: 500px; height: 250px; margin-left: -350px;}
    .parallax-stars { width: 1000px; height: 200px; top: -15px; margin-left: -500px;}
}

@media screen and (max-width: 374px) {
    .main-head__video { width: 285px; padding: 5px; margin: 20px auto 30px auto; border-radius: 20px;}
    .main-head__video iframe { border-radius: 15px; width: 275px; height: 152px;}
}



.main-head {
    .section-wrapper{
        .starry {
            position: absolute;
            z-index: 10;
            bottom: 75px;
            left: 30px;
            @media screen and (max-width: $breakpoint-xl) {
                top: -10px;
                left: -30px;
            }
            @media screen and (max-width: $breakpoint-m) {
                top: -10px;
                left: 10px;
            }
            img {
                height: 300px;
                @media screen and (max-width: $breakpoint-xl) {
                    height: 160px;
                }
                @media screen and (max-width: $breakpoint-m) {
                    height: 150px;
                }
                @media screen and (max-width: $breakpoint-sm) {
                    height: 125px;
                }
            }
        }
    }
}