/* ------------------------------------------------------------ */
/* 3 BUBBLE POINTS WITH ILLUSTRATIONS */
/* ------------------------------------------------------------ */
.bubble-points {
    padding: 60px 0 40px 0;
}

.bubble-points .section-title {
    width: 1200px;
    padding: 20px 600px 20px 30px;
    margin: 0 auto;
    font-size: 44px;
}

.bubble-points .section-title:before {
    left: 30px;
}

.bubble-point {
    background-repeat: no-repeat;
    margin: 30px auto 60px auto;
}

.bubble-point__wrapper {
    width: 1200px;
    margin: 0 auto;
}

.bubble-point__main {
    border-radius: 50px;
    position: relative;
    z-index: 2;
    padding: 1px 0 4px 0;
}

.bubble-point__main q {
    display: block;
    padding: 30px 50px;
    border-radius: 50px;
    font-size: 26px;
    font-weight: 600;
    color: #4a6272;
    background: #fff;
}

.bubble-point__secondary {
    padding: 55px 50px 35px 50px;
    border-radius: 40px;
    font-size: 22px;
    line-height: 1.5;
    position: relative;
    top: -30px;
}

/* ********** Bullet Point positioning and image ********** */
.bubble-point-kid {
    min-height: 484px;
    background-image: url('~@assets/img/bubbles__point-1@2x.jpg');
    background-size: 1296px 484px;
    background-position: top center;
    padding: 20px 0 0 0;
}

.bubble-point-kid .bubble-point__main {
    width: 380px;
    left: 140px;
    background: linear-gradient(90deg,
            rgba(78, 160, 255, 1) 0%,
            rgba(134, 206, 255, 1) 100%);
    box-shadow: 0 4px 16px rgba(77, 160, 255, 0.3);
}

.bubble-point-kid .bubble-point__secondary {
    width: 470px;
    background: #e1f3f7;
}

.bubble-point-mum {
    min-height: 436px;
    background-image: url('~@assets/img/bubbles__point-2@2x.jpg');
    background-size: 1240px 436px;
    background-position: top center;
    padding: 80px 0 0 0;
}

.bubble-point-mum .bubble-point__main {
    width: 500px;
    left: 700px;
    background: linear-gradient(90deg,
            rgba(189, 218, 106, 1) 0%,
            rgba(224, 239, 163, 1) 100%);
    box-shadow: 0 4px 16px rgba(62, 141, 37, 0.3);
}

.bubble-point-mum .bubble-point__secondary {
    width: 500px;
    left: 530px;
    background: #f2f4df;
}

.bubble-point-lgbt {
    min-height: 588px;
    background-image: url('~@assets/img/bubbles__point-3@2x.jpg');
    background-size: 1200px 572px;
    background-position: bottom center;
}

.bubble-point-lgbt .bubble-point__main {
    width: 350px;
    left: 20px; 
    top: 40px;
    background: linear-gradient(90deg,
            rgba(169, 128, 254, 1) 0%,
            rgba(212, 183, 255, 1) 100%);
    box-shadow: 0 4px 16px rgba(122, 79, 212, 0.2);
}

.bubble-point-lgbt .bubble-point__secondary { 
    width: 470px; 
    left: 40px; 
    top: 200px; 
    background: #F6F3FB;
    padding: 35px 50px;
}

.bubble-point-lgbt .bubble-point__secondary strong {
    color: #5027a6;
}

.bubble-point__callout-wrapper { text-align: center; margin: 80px 0 0 0;}

@media screen and (max-width: 1279px) {
    .bubble-points {
        padding: 30px 0;
    }

    .bubble-points .section-title {
        width: 844px;
        padding: 20px 170px 20px 0;
        font-size: 38px;
    }

    .bubble-points .section-title:before {
        left: 0;
    }

    .bubble-point__wrapper {
        width: 904px;
    }

    .bubble-point__main {
        border-radius: 40px;
    }

    .bubble-point__main q {
        border-radius: 40px;
        padding: 25px 40px;
        font-size: 24px;
    }

    .bubble-point__secondary {
        padding: 50px 40px 30px 40px;
        border-radius: 40px;
        font-size: 20px;
    }

    /* Bullet Point positioning and image */
    .bubble-point-kid { background-position: top right;}

    .bubble-point-kid .bubble-point__main {
        width: 350px;
    }

    .bubble-point-kid .bubble-point__secondary {
        width: 430px;
    }

    .bubble-point-mum .bubble-point__main {
        width: 450px;
        left: 454px;
    }

    .bubble-point-mum .bubble-point__secondary {
        width: 480px;
        left: 360px;
    }

    .bubble-point-lgbt .bubble-point__main {
        width: 330px;
        left: 60px;
        top: 20px;
    }

    .bubble-point-lgbt .bubble-point__secondary {
        left: 0;
        top: 190px;
        padding: 30px 40px;
    }

    .bubble-point__callout-wrapper {
        margin: 50px 0 0 0;
    }
}

@media screen and (max-width: 1023px) {
    .bubble-points .section-title {
        width: 688px;
        padding: 20px 0;
        font-size: 34px;
    }

    .bubble-point__wrapper {
        width: 688px;
    }

    .bubble-point__main {
        border-radius: 30px;
    }

    .bubble-point__main q {
        border-radius: 30px;
        padding: 18px 30px;
        font-size: 22px;
    }

    .bubble-point__secondary {
        padding: 44px 30px 20px 30px;
        border-radius: 20px;
        font-size: 18px;
        top: -24px;
    }

    .bubble-point-kid { min-height: 342px; background-size: 916px 342px;}

    .bubble-point-kid .bubble-point__main {
        width: 310px;
        left: 90px;
    }

    .bubble-point-kid .bubble-point__secondary {
        width: 370px;
    }

    .bubble-point-mum { min-height: 354px; background-size: 1007px 354px;}
    
    .bubble-point-mum .bubble-point__main {
        width: 400px;
        left: 288px;
    }

    .bubble-point-mum .bubble-point__secondary {
        width: 430px;
        left: 230px;
    }

    .bubble-point-lgbt { min-height: 420px; background-size: 881px 420px;}
    .bubble-point-lgbt .bubble-point__main {
        width: 300px;
        left: 40px;
    }

    .bubble-point-lgbt .bubble-point__secondary {
        width: 400px;
        top: 190px;
        padding: 30px 40px;
    }
}

@media screen and (max-width: 767px) {
    .bubble-points .section-title {
        width: 100%;
        padding: 15px 20px 0 20px;
        font-size: 24px;
    }

    .bubble-points .section-title:before {
        left: 20px;
    }

    .bubble-point__wrapper {
        width: 100%;
        padding: 0 20px;
    }

    .bubble-point { margin: 30px auto 30px auto;}

    .bubble-point__main {
        max-width: calc(100% - 60px);
        left: 30px !important;
    }

    .bubble-point__main q {
        padding: 14px 20px;
        font-size: 18px;
    }

    .bubble-point__secondary { 
        padding: 33px 20px 15px 20px!important; 
        font-size: 14px; 
        top: -18px!important; 
        width: 100%!important; 
        max-width: 400px; 
        left: 0!important;
        margin: 0 auto;
    }
    .bubble-point-mum {
        min-height: 20px;
        background-image: url('~@assets/img/bubbles__point-2--mobile@2x.jpg');
        background-size: 335px 227px;
        background-position: center top;
        padding: 190px 0 0 0;
    }

    .bubble-point-kid {
        min-height: 20px;
        background-image: url('~@assets/img/bubbles__point-1--mobile@2x.jpg');
        background-size: 335px 235px;
        background-position: center top;
        padding: 190px 0 0 0;
    }

    .bubble-point-kid .bubble-point__main {
        width: 250px;
    }

    .bubble-point-mum .bubble-point__main {
        width: 300px;
    }

    .bubble-point-lgbt {
        min-height: 20px;
        background-image: url('~@assets/img/bubbles__point-3--mobile@2x.jpg');
        background-size: 504px 240px;
        background-position: center top;
        padding: 190px 0 0 0;
    }
    .bubble-point-lgbt .bubble-point__main { top: 0;}
    .bubble-point__callout-wrapper { margin: 0 0;}
}





// Sass


@media screen and (max-width: $breakpoint-xl) {
    .bubble-point-lgbt {
        background-position: center top;
        padding: 270px 0 0 0;
        .bubble-point__main {
            left: 0;
        }
        .bubble-point__secondary{
            top: 0;
            left: 75px;
        }
    }
}

@media screen and (max-width: $breakpoint-l) {
    .bubble-point-lgbt {
        padding: 190px 0 0 0;
    }
}
