.cursor-pointer{
    cursor: pointer;
}

.text-center {
    text-align: center;
}

.d-flex{
    display: flex;
}

.flex-column {
    flex-direction: column;
}